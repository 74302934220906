var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-end flex-md-row flex-column mx-3"},[_c('v-btn',{on:{"click":function($event){return _vm.downloadExcel(_vm.summaryByProductTime.inventories)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Download ")],1)],1),_c('v-data-table',{staticClass:"elevation-1 my-5",attrs:{"items":_vm.summaryByProductTime.inventories,"headers":_vm.headers,"item-key":"inventoryId"},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":'https://prod.bergsoemetals.com/admin/inventory/' + item.inventoryId}},[_vm._v(_vm._s(item.product))])]}},{key:"item.stockedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateYear")(item.stockedDate))+" ")]}},{key:"item.originalQuantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.originalQuantity))+" ")]}},{key:"item.originalValue",fn:function(ref){
var item = ref.item;
return [(item.costAdjustment != 0)?_c('div',[_c('v-menu',{attrs:{"auto":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm._f("currency")(item.originalValue)))])])]}}],null,true)},[_c('v-list',[_c('v-layout',{staticStyle:{"max-height":"60vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"}},[_c('v-simple-table',{staticClass:"ma-4"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v(" Cost Adjustments ")])]),_c('tr',[_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Notes")])])]),_c('tbody',_vm._l((_vm.getCostAdjustments(
                        item.inventoryId,
                        _vm.date
                      )),function(adj,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("currency")(adj.amount)))]),_c('td',[_c('div',{staticClass:"ma-2"},[_vm._v(_vm._s(adj.notes))]),_c('div',{staticClass:"text-caption text-right ma-2"},[_vm._v(" "+_vm._s(_vm._f("formatDateYear")(adj.adjustmentTime))+" ")])])])}),0)])],1)],1)],1)],1)],1):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.originalValue))+" ")])]}},{key:"item.inputQuantityUsed",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")((item.inputQuantityUsed * -1)))+" ")]}},{key:"item.quantitySold",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")((item.quantitySold * -1)))+" ")]}},{key:"item.adjustmentDeductionQuantity",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")((item.adjustmentDeductionQuantity * -1)))+" ")]}},{key:"item.quantityRemaining",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.quantityRemaining))+" ")]}},{key:"item.remainingValue",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.remainingValue))+" ")]}},{key:"item.unitCost",fn:function(ref){
                      var item = ref.item;
return [_c('td',{class:item.unitCost > _vm.summaryByProductTime.unitCost
            ? 'cost-increase'
            : 'cost-decrease'},[_vm._v(" "+_vm._s(_vm._f("currency")(item.unitCost))+" ")])]}}])},[(_vm.summaryByProductTime)?_c('template',{slot:_vm.isMobile ? 'body.prepend' : 'body.append'},[(_vm.isMobile)?_c('tr',{staticClass:"v-data-table__mobile-table-row"},[_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" Total Quantity Remaining ")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.summaryByProductTime.quantity))+" ")])]),_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" Total Quantity Value ")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.summaryByProductTime.remainingValue))+" ")])]),_c('td',{staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" Average Unit Cost ")]),_c('div',{staticClass:"v-data-table__mobile-row__cell"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.summaryByProductTime.unitCost))+" ")])])]):_c('tr',[_c('th',{attrs:{"colspan":"7"}},[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.summaryByProductTime.quantity)))]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.summaryByProductTime.remainingValue)))]),_c('th',[(_vm.summaryByProductTime.quantity  && _vm.summaryByProductTime.quantity != 0)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.summaryByProductTime.remainingValue / _vm.summaryByProductTime.quantity))+" ")]):_vm._e()])])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }