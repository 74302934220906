<template>
  <div>
    <div
      class="d-flex justify-end flex-md-row flex-column mx-3"
    >
        <v-btn @click="downloadExcel(summaryByProductTime.inventories)"
          >
          <v-icon class="mr-2">mdi-microsoft-excel</v-icon> Download
        </v-btn>
    </div>

    <v-data-table
      :items="summaryByProductTime.inventories"
      :headers="headers"
      item-key="inventoryId"
      class="elevation-1 my-5"
    >
      <template v-slot:item.product="{ item }">
        <a
          target="_blank"
          :href="
            'https://prod.bergsoemetals.com/admin/inventory/' + item.inventoryId
          "
          >{{ item.product }}</a
        >
      </template>
      <template v-slot:item.stockedDate="{ item }">
        {{ item.stockedDate | formatDateYear }}
      </template>
      <template v-slot:item.originalQuantity="{ item }">
        {{ item.originalQuantity | formatNumber }}
      </template>
      <template v-slot:item.originalValue="{ item }">
        <div v-if="item.costAdjustment != 0">
          <v-menu auto offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="red--text">{{
                  item.originalValue | currency
                }}</span>
              </div>
            </template>
            <v-list>
              <v-layout column style="max-height: 60vh">
                <v-flex style="overflow: auto">
                  <v-simple-table class="ma-4">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">
                          Cost Adjustments
                        </th>
                      </tr>
                      <tr>
                        <th>Amount</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(adj, index) in getCostAdjustments(
                          item.inventoryId,
                          date
                        )"
                        :key="index"
                      >
                        <td>{{ adj.amount | currency }}</td>
                        <td>
                          <div class="ma-2">{{ adj.notes }}</div>
                          <div class="text-caption text-right ma-2">
                            {{ adj.adjustmentTime | formatDateYear }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-flex></v-layout
              >
            </v-list>
          </v-menu>
        </div>
        <div v-else>
          {{ item.originalValue | currency }}
        </div>
      </template>
      <template v-slot:item.inputQuantityUsed="{ item }">
        {{ (item.inputQuantityUsed * -1) | formatNumber }}
      </template>
      <template v-slot:item.quantitySold="{ item }">
        {{ (item.quantitySold * -1) | formatNumber }}
      </template>
      <template v-slot:item.adjustmentDeductionQuantity="{ item }">
        {{ (item.adjustmentDeductionQuantity * -1) | formatNumber }}
      </template>
      <template v-slot:item.quantityRemaining="{ item }">
        {{ item.quantityRemaining | formatNumber }}
      </template>
      <template v-slot:item.remainingValue="{ item }">
        {{ item.remainingValue | currency }}
      </template>
      <template v-slot:item.unitCost="{ item }">
        <td
          :class="
            item.unitCost > summaryByProductTime.unitCost
              ? 'cost-increase'
              : 'cost-decrease'
          "
        >
          {{ item.unitCost | currency }}
        </td>
      </template>

      <template
        v-if="summaryByProductTime"
        :slot="isMobile ? 'body.prepend' : 'body.append'"
      >
        <tr v-if="isMobile" class="v-data-table__mobile-table-row">
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">
              Total Quantity Remaining
            </div>
            <div class="v-data-table__mobile-row__cell">
              {{ summaryByProductTime.quantity | formatNumber }}
            </div>
          </td>
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">
              Total Quantity Value
            </div>
            <div class="v-data-table__mobile-row__cell">
              {{ summaryByProductTime.remainingValue | currency }}
            </div>
          </td>
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__header">
              Average Unit Cost
            </div>
            <div class="v-data-table__mobile-row__cell">
              {{ summaryByProductTime.unitCost | currency }}
            </div>
          </td>
        </tr>
        <tr v-else>
          <th colspan="7">Total</th>
          <th>{{ summaryByProductTime.quantity | formatNumber }}</th>
          <th>{{ summaryByProductTime.remainingValue | currency }}</th>
          <th>
            <span v-if="summaryByProductTime.quantity  && summaryByProductTime.quantity != 0">
              {{ summaryByProductTime.remainingValue / summaryByProductTime.quantity | currency }}
            </span>
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { json2excel } from "js2excel";
import { parseDomain } from "parse-domain";

export default {
  props: {
    summaryByProductTime: {
      type: Object,
      required: true,
    },
    date: {
      type: [String, Date],
    },
  },
  data: () => ({
    headers: [
      { text: "Product", value: "product" },
      { text: "Date", value: "stockedDate" },
      { text: "Original Quantity", value: "originalQuantity" },
      { text: "Initial Value", value: "originalValue" },
      { text: "Process Input", value: "inputQuantityUsed" },
      { text: "Sold", value: "quantitySold" },
      { text: "Adjustments Deduction", value: "adjustmentDeductionQuantity" },
      { text: "Remaining Quantity", value: "quantityRemaining" },
      { text: "Remaining Value", value: "remainingValue" },
      { text: "Unit Cost", value: "unitCost" },
    ],
    domain: null,
  }),
  mounted() {
    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  methods: {
    downloadExcel(data) {
      try {
        // console.log(flatSubmissions);
        json2excel({
          data: data,
          name: "inventory-by-product",
          formateDate: "yyyy-mm-dd",
        });
      } catch (e) {
        console.log(e);
        this.addErrorMessage("Error exporting inventory balance for product");
      }
    },
    getCostAdjustments(inventoryId, balanceDate) {
      return this.costAdjustmentService.getCostAdjustmentDetails(
        inventoryId,
        balanceDate
      );
    },
  },
};
</script>

<style>
.cost-increase {
  background-color: rgb(253, 161, 161);
  padding: 5px;
}
.cost-decrease {
  background-color: rgb(166, 251, 166);
}
</style>
